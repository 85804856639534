import React from "react";

/*
    SummerEyesIcon(props):
        - Resizable SummerEyes icon (just the circles) that can be placed in text
        - Properties:
            - className: Tailwind class system that can be applied to the svg
            - lightMode: Boolean value of whether to display light version of logo (i.e., dark fill for white background)
*/

export default function SummerEyesIcon(props) {
  if (props.lightMode) {
    return (
      <>
        <svg
          width="253"
          height="254"
          viewBox="0 0 253 254"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={props.className}
        >
          <path
            d="M168.377 115.635C190.924 154.688 177.543 204.625 138.49 227.172C99.4371 249.719 49.5001 236.339 26.9528 197.286C4.40549 158.232 17.7861 108.296 56.8391 85.7482C95.8922 63.2009 145.829 76.5815 168.377 115.635Z"
            fill="url(#paint0_linear_1_775)"
          />
          <path
            d="M177.977 172.139C180.537 159.066 179.86 145.566 176.005 132.815C172.15 120.065 165.233 108.451 155.859 98.9868C146.485 89.5227 134.938 82.4959 122.225 78.519C109.511 74.5422 96.0182 73.7362 82.9219 76.1713C82.9219 76.1713 80.1832 126.815 102.623 149.47C125.062 172.124 177.977 172.139 177.977 172.139Z"
            fill="url(#paint1_linear_1_775)"
          />
          <g filter="url(#filter0_bi_1_775)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M216.597 97.6013C216.597 63.9783 189.34 36.7214 155.717 36.7214C122.094 36.7214 94.8368 63.9783 94.8368 97.6013C94.8368 131.224 122.094 158.481 155.717 158.481C189.34 158.481 216.597 131.224 216.597 97.6013ZM237.318 97.6013C237.318 52.534 200.784 15.9998 155.717 15.9998C110.649 15.9998 74.1152 52.534 74.1152 97.6013C74.1152 142.669 110.649 179.203 155.717 179.203C200.784 179.203 237.318 142.669 237.318 97.6013Z"
              fill="#0A083D"
            />
          </g>
          <defs>
            <filter
              id="filter0_bi_1_775"
              x="69.5465"
              y="11.431"
              width="172.341"
              height="172.341"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.28437" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_1_775"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_1_775"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="1.95949" />
              <feGaussianBlur stdDeviation="0.979743" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.33 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect2_innerShadow_1_775"
              />
            </filter>
            <linearGradient
              id="paint0_linear_1_775"
              x1="-105.881"
              y1="488.986"
              x2="170.798"
              y2="78.0146"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.652431" stopColor="#0F6FFF" />
              <stop offset="0.984111" stopColor="#81E6F7" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_1_775"
              x1="145.934"
              y1="93.464"
              x2="114.571"
              y2="154.697"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#66F0CA" />
              <stop offset="0.857061" stopColor="#0798BC" />
            </linearGradient>
          </defs>
        </svg>
      </>
    );
  }

  return (
    <>
      <svg
        width="253"
        height="254"
        viewBox="0 0 253 254"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
      >
        <path
          d="M168.377 115.635C190.924 154.688 177.543 204.625 138.49 227.172C99.4371 249.719 49.5001 236.339 26.9528 197.286C4.40549 158.232 17.7861 108.296 56.8391 85.7482C95.8922 63.2009 145.829 76.5815 168.377 115.635Z"
          fill="url(#paint0_linear_1_781)"
        />
        <path
          d="M177.977 172.139C180.537 159.066 179.86 145.566 176.005 132.815C172.15 120.065 165.233 108.451 155.859 98.9868C146.485 89.5227 134.938 82.4959 122.225 78.519C109.511 74.5422 96.0182 73.7362 82.9219 76.1713C82.9219 76.1713 80.1832 126.815 102.623 149.47C125.062 172.124 177.977 172.139 177.977 172.139Z"
          fill="url(#paint1_linear_1_781)"
        />
        <g filter="url(#filter0_bi_1_781)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M216.597 97.6014C216.597 63.9784 189.34 36.7215 155.717 36.7215C122.094 36.7215 94.8368 63.9784 94.8368 97.6014C94.8368 131.225 122.094 158.481 155.717 158.481C189.34 158.481 216.597 131.225 216.597 97.6014ZM237.318 97.6014C237.318 52.5341 200.784 15.9999 155.717 15.9999C110.649 15.9999 74.1152 52.5341 74.1152 97.6014C74.1152 142.669 110.649 179.203 155.717 179.203C200.784 179.203 237.318 142.669 237.318 97.6014Z"
            fill="white"
          />
        </g>
        <defs>
          <filter
            id="filter0_bi_1_781"
            x="69.5465"
            y="11.4311"
            width="172.341"
            height="172.341"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.28437" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_1_781"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur_1_781"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1.95949" />
            <feGaussianBlur stdDeviation="0.979743" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.33 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect2_innerShadow_1_781"
            />
          </filter>
          <linearGradient
            id="paint0_linear_1_781"
            x1="-105.881"
            y1="488.986"
            x2="170.798"
            y2="78.0146"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.652431" stopColor="#0F6FFF" />
            <stop offset="0.984111" stopColor="#81E6F7" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1_781"
            x1="145.934"
            y1="93.464"
            x2="114.571"
            y2="154.697"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#66F0CA" />
            <stop offset="0.857061" stopColor="#0798BC" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
}
