import React from "react";
import { XCircleIcon } from '@heroicons/react/solid'

/*
    ErrorBlockMessage component: Appears to provide the error once the user has attempted to login & failed.
    Takes the following props:
        - errorState: The error message to be shown in the block message
*/
export default function ErrorBlockMessage(props) {
    
    // if the error message is empty, return nothing
    if (props.errorState){
        return (
            <>
                <div className="rounded-md bg-red-50 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                            <h3 className="text-sm font-medium text-red-700">{props.errorState}</h3>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    else{
        return null;
    }
}