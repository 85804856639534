/*
    Login page. Utilises server-side rendering. 
    Following considerations implemented:
        - If user is already logged in, they should not be able to access page -> redirect to profile page instead
        - Utilizes firebase auth
*/

// import external
import React from "react";
import firebase from "firebase/app";
import "firebase/auth";
import nookies from "nookies";

// import (internal)
import PageDefault from "../components/PageDefault";
import ErrorBlockMessage from "../components/appUI/ErrorBlockMessage";
import { verifyIdToken } from "../components/auth/FirebaseAdmin";
import { firebaseClient } from "../components/auth/FirebaseClient";
import DottedBg from "../components/pageUI/DottedBg";

// TODO: Incorporate all the nice Next.js stuff in to this page
import Head from "next/head";
import Link from "next/link";
import Image from "next/image";
import SummerEyesIcon from "../components/branding/SummerEyesIcon";
import SummerEyesButton from "../components/branding/SummerEyesButton";

/* 
    Server Side Rendering hook:
        - Create a check on server side to see if user is alredy logged in
        - If logged in, redirect to user profile page
*/
export async function getServerSideProps(context) {
  try {
    const cookies = nookies.get(context); // get the cookies

    // if no auth token in the cookies, proceed as normal
    if (!cookies.token) {
      return { props: {} };
    }

    const token = await verifyIdToken(cookies.token); // verify if the token is legitimate or not
    const { uid, email } = token;

    // if auth token exists in the cookies, push them to the profile page, and log it
    if (token) {
      context.res.writeHead(303, { location: "/dashboard" });
      context.res.end();
      console.log(
        "!! UNINTENDED_USER_BEHAVIOUR: Logged-in user tried to access the log-in page"
      );
      return { props: {} };
    } else {
      return { props: {} };
    }
  } catch (err) {
    context.res.writeHead(302, { location: "/" }); // redirect to home if errors - I don't want weird stuff happening in my login page
    context.res.end();
    console.log(
      "!! UNHANDLED_ERROR: Occurring when trying to check if user should be shown login page. " +
        err
    );
    return { props: {} };
  }
}

/*
    generateErrorMessage(error): Handles an invalid user login attempt and triggers the ErrorBlockMessage component
        - error: The error object from the Firebase auth function
        - Assesses whether the error is handled (e.g., validation, or not)
*/
function generateErrorMessage(error) {
  // if the error is handled, return a nice/custom message
  if (error.code === "auth/wrong-password") {
    return "Your password is incorrect";
  } else if (error.code === "auth/user-not-found") {
    return "Your email is incorrect";
  } else if (error.code === "auth/invalid-email") {
    return "Your email is incorrect";
  } else if (error.code === "auth/user-disabled") {
    return "Your account is not active - please contact customer support";
  } else if (error.code === "auth/too-many-requests") {
    return "Too many login attempts. Please try again later";
  } else {
    // if not handled, return the error message
    return "Unexpected user login error. " + error.message;
  }
}

export default function Login({ session }) {
  firebaseClient();

  const [email, setEmail] = React.useState("");
  const [pass, setPass] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");

  const [loading, setLoading] = React.useState(false);

  // method to attempt login through firebase when button is clicked
  const submitLoginDetails = async (event, path) => {
    setLoading(true);
    event.preventDefault();
    console.log(event, path);
    await firebase
      .auth()
      .signInWithEmailAndPassword(email, pass)
      .then(function () {
        window.location.href = "/dashboard";
        setLoading(false);
      })
      .catch(function (error) {
        setErrorMessage(generateErrorMessage(error)); // create a nice string message & trigger the ErrorMessageBlock
        setLoading(false);
      });
  };

  function renderLoginButton(loadingState) {
    if (loadingState) {
      return (
        <>
          <SummerEyesButton full disabled>
            <span className="flex items-center">
              <svg
                role="status"
                className="mr-2 h-6 w-6 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            </span>
          </SummerEyesButton>
        </>
      );
    } else {
      return (
        <>
          <SummerEyesButton
            full
            designStyle="positivePath"
            onClick={(event) => submitLoginDetails(event, "/")}
            customTailwind="group"
            formSubmit
          >
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              {
                //<!-- Heroicon name: solid/lock-closed
              }
              <svg
                className="h-5 w-5 text-white group-hover:text-gray-50"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
            Sign in
          </SummerEyesButton>
        </>
      );
    }
  }

  return (
    <>
      <PageDefault pageTitle="Login | SummerEyes">
        <DottedBg />
        {
          //Taken from TailwindUI Components
        }
        <div className="flex min-h-full items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
          <div className="w-full max-w-md space-y-8 ">
            <div>
              <div className="mx-auto h-16 w-16">
                <SummerEyesIcon className="h-16 w-16" lightMode />
              </div>
              <h2 className="mt-6 text-center text-3xl font-bold text-gray-900">
                Sign in to your account
              </h2>
              <p className="mt-2 text-center text-sm text-gray-600">
                Or
                <Link href="/register">
                  <a className="font-medium text-summer-bright hover:text-summer-bright">
                    {" "}
                    register a new account{" "}
                  </a>
                </Link>
              </p>
            </div>
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
              <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <ErrorBlockMessage errorState={errorMessage} />
                <form className="space-y-6">
                  <input type="hidden" name="remember" value="true" />
                  <div className="-space-y-px rounded-md shadow-sm">
                    <div>
                      <label htmlFor="email-address" className="sr-only">
                        Email address
                      </label>
                      <input
                        id="email-address"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="focus:border-summer-bright00 focus:ring-summer-bright00 relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:outline-none sm:text-sm"
                        placeholder="Email address"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div>
                      <label htmlFor="password" className="sr-only">
                        Password
                      </label>
                      <input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className="focus:border-summer-bright00 focus:ring-summer-bright00 relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:outline-none sm:text-sm"
                        placeholder="Password"
                        onChange={(e) => setPass(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        defaultChecked
                        className="h-4 w-4 rounded border-gray-300 text-summer-bright focus:ring-summer-bright"
                      />
                      <label
                        htmlFor="remember-me"
                        className="ml-2 block text-sm text-gray-900"
                      >
                        {" "}
                        Remember me{" "}
                      </label>
                    </div>

                    <div className="text-sm">
                      <Link href="/requestPasswordReset">
                        <a className="font-medium text-summer-bright hover:text-summer-bright">
                          {" "}
                          Forgot your password?{" "}
                        </a>
                      </Link>
                    </div>
                  </div>

                  <div>{renderLoginButton(loading)}</div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </PageDefault>
    </>
  );
}
